/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;

/* This injects Tailwind's component classes and any component classes registered by plugins. */
@tailwind components;

/* This injects Tailwind's utility classes and any utility classes registered by plugins. */
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Inter-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Inter-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/Inter-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/Inter-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/Inter-ExtraBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/Inter-Black.ttf') format('truetype');
  }
}

/* Default styles. */
html {
  height: 100%;
  background-color: white !important;
  font-family: 'Inter' !important;
}

body {
  height: 100%;
  /* overflow-x: hidden; */
}

#root {
  height: 100%;
}
